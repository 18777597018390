import React from 'react';
import { Button as Btn, ButtonProps } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface CustomButtonProps extends ButtonProps {
  primary?: boolean;
  withIcon?: boolean;
}

export const Button: React.FC<CustomButtonProps> = ({
  primary = false,
  withIcon = false,
  children,
  ...rest
}) => {
  return React.createElement(
    Btn,
    {
      rightIcon: withIcon ? React.createElement(ChevronRightIcon) : undefined,
      variant: primary ? 'solid' : 'outline',
      ...rest,
    },
    children
  );
};
