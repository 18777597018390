/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Flex, Text } from '@chakra-ui/react';
import { signIn } from 'next-auth/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextPageWithLayout } from './_app';
import routes from '@src/utils/routes';
import { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth';
import { authOptions } from './api/auth/[...nextauth]';
import Image from 'next/image';
import MocaLogin from '../../public/static/images/moca-login.svg';
import LoginHeader from '../../public/static/images/login-header.svg';
import GoogleLogo from '../../public/static/images/starting/google-logo.png';
import { ReactElement } from 'react';
import { DEFAULT_NAMESPACES } from '@src/constants';
import { Button } from '@src/design-system/components/Button';

const Page: NextPageWithLayout = () => {
  return (
    <Flex
      height="100vh"
      align="center"
      justify="center"
    >
      <Flex
        w="full"
        direction="column"
        align="center"
        justify="center"
        p={8}
      >
        <Image
          src={LoginHeader}
          alt="Imagen"
        />
        <Text
          color="neutral.500"
          fontSize="2xl"
          mt="128px"
        >
          ¡Bienvenido a Moca!
        </Text>

        <Text
          color="neutral.500"
          fontSize="xl"
          mt="28px"
        >
          Ingresa con tu correo electrónico
        </Text>

        <Button
          w={{ md: 380 }}
          mt="50px"
          gap={1}
          onClick={() => signIn('google', { callbackUrl: routes.setup }, { prompt: 'login' })}
        >
          <Image
            src={GoogleLogo}
            alt="Imagen"
            width={24}
          />
          Inicia sesión con Google
        </Button>
      </Flex>

      <Flex
        w="full"
        h="full"
        direction="column"
        align="center"
        justify="center"
        bg="primary.500"
        display={{ base: 'none', md: 'flex' }}
      >
        <Image
          src={MocaLogin}
          alt="Imagen"
        />
      </Flex>
    </Flex>
  );
};

Page.getLayout = function getLayout(page: ReactElement) {
  return <>{page}</>;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, res, locale } = context;
  const session = await getServerSession(req, res, authOptions);

  if (session) {
    return {
      redirect: {
        destination: routes.setup,
        permanent: false,
      },
    };
  }

  const localeTranslations = await serverSideTranslations(locale, [
    ...DEFAULT_NAMESPACES,
    'starting',
  ]);
  return {
    props: {
      session,
      ...localeTranslations,
    },
  };
};

export default Page;
